import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import bg from '../../images/bg-solution.jpg';

import { Layout } from '../../components/organisms';
import { Footer, ProductDetail, ProductDetailProps } from '../../components/molecules';
import { PageWrapper } from '../../components/atoms';
import { colors } from '../../styles/variables';

const Container = styled.div`
  padding-top: 72px;
  flex-grow: 1;
`;

const Wrapper = styled(PageWrapper)`
  padding-top: 27px;
`;

const ColorOverlay = styled.div`
  background-color: ${colors.purple};
  position: fixed;
  top: 0;
  left: 0;
  height: 80%;
  width: 100%;
  opacity: 0.6;
  z-index: -1;
`;

const BackgroundImage = styled.img`
  position: fixed;
  z-index: -1;
  object-fit: cover;
  object-position: left top;
  /* object-fit: contain;
  object-position: left; */
  top: 0;
  left: 0;
  height: 80%;
  width: 100%;
`;

type Props = {
  data: {
    strapiProduct: ProductDetailProps;
  };
};

export default function Solution({ data }: Props) {
  return (
    <Layout title={data.strapiProduct.name}>
      <Container>
        <Wrapper>
          <BackgroundImage src={bg} aria-hidden />
          <ColorOverlay aria-hidden />
          <ProductDetail {...data.strapiProduct} />
        </Wrapper>
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query ($name: String) {
    strapiProduct(name: { eq: $name }) {
      id
      name
      commisionning
      target
      conditions
      services
      monitoring
      file {
        mime
        url
        name
      }
      category {
        title
      }
    }
  }
`;
